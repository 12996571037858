/* eslint-disable react-hooks/exhaustive-deps */
import React, { ComponentProps, FC, useEffect } from 'react'
import { useHistory, useLocation, generatePath, Link, NavLink } from 'react-router-dom'
import * as H from 'history'

export {
  Route,
  Switch,
  BrowserRouter as Router,
  matchPath,
  useParams,
  generatePath,
  useRouteMatch,
} from 'react-router-dom'
export { useHistory, useLocation }

export type RoutePath =
  | string
  | {
      pathname: string
      state?: {
        noScroll?: boolean
        redirect?: RoutePath
      }
    }

export interface LinkProps extends ComponentProps<typeof Link> {
  next?: RoutePath
  query?: Record<string, string | number>
  to: RoutePath
}

interface RedirectProps extends LinkProps {
  replace?: boolean
}

type RedirectReturnType = (
  to: RoutePath,
  query?: Record<string, string | number>,
  next?: RoutePath,
) => void

/**
 * @example
 * replaceHistory = useReplaceHistory()
 * replaceHistory('xxx/:id', {id: 1}, { to:'yyy/:id', query:{id:2} })
 */
export const useReplaceHistory = (): RedirectReturnType => {
  const history = useHistory()
  return (to, query, next = '') => {
    const converted = convertedPropsForRedirect({ to, query, next })
    history.replace(converted)
  }
}

/**
 * @example
 * redirect = useRedirect()
 * redirect({ to: path, next:{ to:next_path, query:{uuid:uuid} } })
 */
export const useRedirect = (): RedirectReturnType => {
  const history = useHistory()
  return (to, query, next = '') => {
    history.push({
      ...(next && { state: { redirect: next } }),
      ...convertedPropsForRedirect({ to, query }),
    })
  }
}

/**
 * @example
 * redirect = useRedirectNext()
 * redirect() - redirects to saved next
 *    or
 * redirect(to, query) - redirects to defaultPath when there are no saved next
 *
 */

interface LocationState extends H.LocationState {
  redirect: RoutePath
}
export const useRedirectNext = (): RedirectReturnType => {
  const location = useLocation<LocationState>()
  const redirect = useRedirect()
  const redirectTo = location?.state?.redirect
  //TODO: save next state to sessionStorage
  return (to, query, next = '') =>
    redirectTo ? redirect(redirectTo, query, next) : redirect(to, query, next)
}

/**
 * @example
 * <Redirect to={login} next={{ to: currentPath, query: {uuid} }} />
 */

export const Redirect = ({ to, query, next, replace }: RedirectProps): boolean => {
  const redirect = useRedirect()
  const replaceHistory = useReplaceHistory()

  useEffect(() => {
    replace ? replaceHistory(to, query, next) : redirect(to, query, next)
  }, [])
  return false
}

export const RouterLink: FC<LinkProps> = (props) => <Link {...convertProps(props)} />

export const RouterNavLink: FC<LinkProps> = (props) => <NavLink {...convertProps(props)} />

export const getLastParamFromRoute = (route: string): string =>
  route.substring(route.lastIndexOf('/') + 1)

const convertProps = ({ to, query, next, ...props }: LinkProps): ComponentProps<typeof NavLink> => {
  if (typeof to === 'string')
    return {
      ...props,
      to: {
        pathname: query ? generatePath(to, query) : to,
        ...(next && { state: { redirect: next } }),
      },
    }
  return {
    ...props,
    to: {
      ...to,
      pathname: query ? generatePath(to.pathname, query) : to.pathname,
      ...(next && { state: { redirect: next } }),
    },
  }
}

const convertedPropsForRedirect = ({ to, query, next, ...props }: LinkProps): H.LocationState => {
  if (typeof to === 'string') {
    const url = new URL(to, window.location.origin)
    const cleanPathname = url.pathname
    const search = url.search

    return {
      ...props,
      pathname: query ? generatePath(cleanPathname, query) : cleanPathname,
      search,
      ...(next && { state: { redirect: next } }),
    }
  }

  const url = new URL(to.pathname, window.location.origin)
  const cleanPathname = url.pathname
  const search = url.search

  return {
    ...props,
    ...to,
    pathname: query ? generatePath(cleanPathname, query) : cleanPathname,
    search,
    ...(next && { state: { redirect: next } }),
  }
}
